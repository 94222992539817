/**
 * Einige Hilfsfunktionen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@function get-hue($color) {
	@return round(strip-unit(hue($color)));
}

@function get-saturation($color) {
	@return round(strip-unit(saturation($color))) + 0%;
}

@function get-lightness($color) {
	@return round(strip-unit(lightness($color))) + 0%;
}

/**
 * Zeilenhoehe kalkulieren.
 *
 * @param number $base
 * @param number $target
 *
 * return number
 */
@function calc-line-height($base : 16px, $target : 16px) {
	@return strip-unit($target) / strip-unit($base);
}

/**
 * Kleinster gemeinsamer Teiler.
 *
 * @param number $a
 * @param number $b
 *
 * return number
 */
@function gcd($a, $b) {
	@while ($b > 0) {
		$c : $a % $b;
		$a : $b;
		$b : $c;
	}

	@return $a;
}

/**
 * Aspect-Ratio durch Angabe von Höhe und Breite bestimmen.
 *
 * @param number $x  Breite (Numerator 1)
 * @param number $y  Höhe  (Denominator 1)
 *
 * return number Prozent
 */
@function calc-aspect-ratio($x : 16, $y: 9)
{
	$error : '';
	$res   : 0;
	$x     : strip-unit($x);
	$y     : strip-unit($y);

	@if ($x <= 0 or $y <= 0) {
		$error : "Die Originalbreite und -höhe muss angegeben werden (calc-aspect-ratio())";
	}

	@if ($error == '') {
		$res : ($y / $x) * 100%;
	} @else {
		@warn $error;
	}

	@return $res;
}

/**
 * Durch Angabe einer Originalbreite und -hoehe und einer Zielbreite oder -hoehe
 * wird per Berechnung des Seitenverhaeltnisses die fehlende Zielbreite- oder
 * -hoehe berechnet.
 *
 * @param number $x  Originalbreite (Numerator 1)
 * @param number $y  Originalhoehe  (Denominator 1)
 * @param number $x2 Zielbreite (Numerator 2)
 * @param number $y2 Zielhoehe (Denominator 2)
 *
 * return number Zielbreite oder -hoehe
 */
@function calc-aspect-ratio-to-target($x : 0, $y: 0, $x2 : 0, $y2 : 0)
{
	$error : '';
	$res   : 0;
	$x     : strip-unit($x);
	$y     : strip-unit($y);
	$x2    : strip-unit($x2);
	$y2    : strip-unit($y2);

	@if ($x <= 0 or $y <= 0) {
		$error : "Die Originalbreite und -höhe muss angegeben werden (calc-aspect-ratio())";
	}

	@if ($x2 <= 0 and $y2 <= 0) {
		$error : "Es muss eine Zielbreite oder -höhe angegeben werden (calc-aspect-ratio())";
	}

	@if ($error == '') {
		// Zielhoehe berechnen
		@if ($x2 > 0)
		{
			$res : round($x2 / ($x / $y));
		}

		// Zielbreite berechnen
		@if ($y2 > 0)
		{
			$res : round($y2 * ($x / $y));
		}
	} @else {
		@warn $error;
	}

	@return $res;
}

/// Durch Angabe einer Originalbreite und -höhe und einer Zielbreite oder -höhe wird per Berechnung des Seitenverhältnisses die fehlende Zielbreite- oder -höhe ermittelt.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {length} $x - Numerator 1 (reale Breite)
/// @parameter {length} $y - Denominator 1 (reale Höhe)
/// @parameter {length} $x2 - Numerator 2 (neue Breite)
/// @parameter {length} $y2 - Denominator 2 (neue Höhe)
/// @require   {function} strip-unit
/// @return    {length}
@function calc-aspect-ratio-new($x : 0, $y: 0, $x2 : 0, $y2 : 0)
{
	$error : '';
	$res   : 0;
	$x     : strip-unit($x);
	$y     : strip-unit($y);
	$x2    : strip-unit($x2);
	$y2    : strip-unit($y2);

	@if ($x <= 0 or $y <= 0) {
		$error : "Die Originalbreite und -höhe muss angegeben werden (calc-aspect-ratio())";
	}

	@if ($x2 <= 0 and $y2 <= 0) {
		$error : "Es muss eine Zielbreite oder -höhe angegeben werden (calc-aspect-ratio())";
	}

	@if ($error == '') {
		// Zielhoehe berechnen
		@if ($x2 > 0)
		{
			$res : round($x2 / ($x / $y));
		}

		// Zielbreite berechnen
		@if ($y2 > 0)
		{
			$res : round($y2 * ($x / $y));
		}
	} @else {
		@warn $error;
	}

	@return $res;
}