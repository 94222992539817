/**
 * Styled Checkboxes/Radiobuttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$cbs-rbs-inline-margin : $margin-base-horizontal;

$cbs-bg-color         : $input-bg-color !default;
$cbs-border-color     : $input-border-color !default;
$cbs-border-style     : $input-border-style;
$cbs-border-radius    : $input-border-radius;
$cbs-border-width     : $input-border-width;
$cbs-color            : $brand-secondary !default;
$cbs-font-size        : 16px;
$cbs-icon-font-family : 'Font Awesome 5 Pro';
$cbs-icon-check       : "\f00c";
$cbs-label-padding    : 15px;
$cbs-padding          : 24px;
$cbs-size             : 24px;

$rbs-bg-color      : $cbs-bg-color !default;
$rbs-border-color  : $cbs-border-color !default;
$rbs-border-style  : $cbs-border-style;
$rbs-border-width  : $cbs-border-width;
$rbs-color         : $cbs-color !default;
$rbs-label-padding : $cbs-label-padding;
$rbs-padding       : 24px;
$rbs-size          : $cbs-size;
$rbs-size-dot      : 10px;