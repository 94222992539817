/**
 * Komponenten
 *
 * Grundkonfiguration und Konfigurationsimport einzelner Komponenten.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Globaler Innenabstaende einzelnder Komponenten (Buttons, Input).
 *
 * ========================================================================== */

$comp-margin-horizontal-base   : 15px;
$comp-margin-vertical-base     : 6px;
$comp-margin-horizontal-large  : 20px;
$comp-margin-vertical-large    : 10px;
$comp-margin-horizontal-small  : 10px;
$comp-margin-vertical-small    : 5px;
$comp-margin-horizontal-xsmall : 5px;
$comp-margin-vertical-xsmall   : 1px;

$comp-padding-horizontal-base   : 15px;
$comp-padding-vertical-base     : 10px;
$comp-padding-horizontal-large  : 20px;
$comp-padding-vertical-large    : 15px;
$comp-padding-horizontal-small  : 10px;
$comp-padding-vertical-small    : 5px;
$comp-padding-horizontal-xsmall : 8px;
$comp-padding-vertical-xsmall   : 4px;

/* =============================================================================
 *
 * Globale Rahmenangaben.
 *
 * ========================================================================== */

$comp-border-style : solid;
$comp-border-width : 3px;

/* =============================================================================
 *
 * Globaler Borderadius einzelner Komponenten.
 *
 * ========================================================================== */

$comp-border-radius-base  : $border-radius-base;
$comp-border-radius-large : $border-radius-large;
$comp-border-radius-small : $border-radius-small;

/* =============================================================================
 *
 * Konfigurationsimport einzelner komponenten.
 *
 * ========================================================================== */

@import 'components/_links';
@import 'components/_forms';
@import 'components/_checkbox-radio-styled';
@import 'components/_images';
@import 'components/_indicator';
@import 'components/_rating-stars';
@import 'components/_dropdowns';
@import 'components/_alerts';
@import 'components/_buttons';
@import 'components/_navs';
@import 'components/_paginations';
@import 'components/_tabs';
@import 'components/_tables';
@import 'components/_lists';
@import 'components/_list-groups';
@import 'components/_panels';
@import 'components/_wells';
@import 'components/_collapse';
@import 'components/_modals';
@import 'components/_tooltip';
@import 'components/_popovers';
@import 'components/_videos-audio';
@import 'components/_vcards';
@import 'components/_galleries';
@import 'components/_googlemaps';
@import 'components/_stacks';
@import 'components/_articles';
